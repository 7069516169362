<template>
  <v-dialog
    overlay-color="rgba(34, 34, 34, 0.27)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="350"
    width="100%"
    persistent
  >
    <v-card>
      <v-card-title class="ft text-md ml-n2">Bulk Upload</v-card-title>

      <v-container class="mt-n4">
        <span class="ml-1 ft text-sm mb-2"
          >Upload a <kbd>csv</kbd> or <kbd>excel</kbd> file</span
        >
        <div class="d-flex flex-row flex-full">
          <v-file-input
            show-size
            v-model="file"
            class="ft font-weight-medium"
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="Choose a file to upload"
          ></v-file-input>
        </div>
        <div class="d-flex mx-2 mb-2 flex-row flex-grow-1 flex-full">
          <span style="font-size: 12px" class="ft grey--text text--darken-1"
            >Download sample template here</span
          >
        </div>
        <div class="d-flex mx-1 flex-row flex-full">
          <v-btn
            x-small
            @click.stop="
              $store.dispatch('contact/fileDownload', {
                fileName: 'contacts',
                ext: 'xlsx',
              })
            "
            text
            outlined
            class="mx-1"
          >
            <span class="ft primary--text font-weight-bold font-size-sm"
              >Download Excel</span
            >
          </v-btn>
          <v-btn
            x-small
            text
            tag="a"
            target="_blank"
            outlined
            @click.stop="
              $store.dispatch('contact/fileDownload', {
                fileName: 'contacts',
                ext: 'csv',
              })
            "
          >
            <span class="ft primary--text font-weight-bold font-size-sm"
              >Download CSV</span
            >
          </v-btn>
        </div>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="accent"
          class="text-capitalize text-sm ft"
          text
          @click="action"
        >
          Cancel
        </v-btn>

        <v-btn
          :disabled="!file || loading"
          :loading="loading"
          color="primary"
          class="text-capitalize text-sm ft"
          @click="uploadFile"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
export default {
  name: 'BulkUpload',
  props: {
    state: {
      type: Boolean,
      required: true,
    },
    loading: Boolean,
  },
  data: () => ({
    file: null,
  }),
  computed: {
    ...mapGetters({
      resetFormState: 'getResetFormState',
    }),
  },
  watch: {
    resetFormState(value) {
      if (value) {
        this.file = null;
      }
      setTimeout(() => {
        this.$store.dispatch('resetFormValues', false, { root: true });
      }, 1000);
    },
  },
  methods: {
    uploadFile() {
      this.$emit('uploadFile', this.file);
    },
    action() {
      this.$emit('actions', { idx: 'upload', state: false });
    },
  },
};
</script>

<style scoped></style>
